<template>
  <div class="container">
    <div class="page-header">
      <div class="page-header__ell">
        <h1 class="page-title">Редактировать отдельную площадку</h1>
      </div>
      <div class="page-header__ell page-header__flex">
        <!--
        <router-link :to="{ name: this.$routeName.PLACE_CLUB_EDIT }" class="add-link">
          Изменить тип
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><rect width="28" height="28" rx="14" fill="#E54C4C"/><path d="M15.416 20.582h5.315" stroke="#1B1B1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path clip-rule="evenodd" d="M14.713 8.13c.614-.783 1.606-.742 2.39-.128l1.158.909c.783.614 1.06 1.566.447 2.35L11.8 20.075c-.23.295-.583.47-.958.473l-2.664.035-.604-2.596c-.085-.364 0-.748.231-1.044l6.908-8.812z" stroke="#1B1B1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.419 9.78l3.995 3.132" stroke="#1B1B1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </router-link>
        -->
      </div>
    </div>
    <SingleRoomAddForm
      v-if="place"
      :formData="place"
      ref="PlaceSingleAddForm"
      @removeImage="removeImage"

      :editForm="true"
    />
    <div class="btn-gp">
      <b-button size="lg" variant="danger" @click="save">Сохранить</b-button>
      <router-link :to="{name: this.$routeName.PLACE_LIST}" class="btn btn-link btn-lg">Отмена</router-link>
    </div>
  </div>
</template>

<script>

import SingleRoomAddForm from "@component/Form/SingleRoomAddForm";

import GetGoogleMap from "@library/GetGoogleMap";


export default {
  components: {
    SingleRoomAddForm
  },
  props: {
    placeId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      place: {},
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load(){
      RequestManager.Place.getPlaceById({
        id: this.placeId,
      }).then((data) => {
        this.place = Object.assign({}, data);
        this.place.address = {
          data: {
            geo_lat: data.location.latitude,
            geo_lon: data.location.longitude,
          },
          value: data.address
        };
      });
    },
    removeImage(imageObj){
      RequestManager.Place.deletePlaceMedia({
        id: this.placeId,
        fileId: imageObj.getId(),
      });
    },
    async save() {
      let formData = this.$refs.PlaceSingleAddForm.formSubmitGetData();
      if(!formData) { return; }

      let sendData = Object.assign({}, formData);
      delete sendData.courtmedia_set;

      // location set
      try {
        const gObj = await GetGoogleMap();
        const coord = await gObj.getAddressCoordinate(sendData.address.value);
        //
        sendData.address.data.geo_lat = coord.lat;
        sendData.address.data.geo_lon = coord.lon;
      } catch (e) {
        this.$dialogs.alert('Не удалось определить адресс');
      }

      sendData.location = JSON.stringify({
        latitude: sendData.address.data.geo_lat,
        longitude: sendData.address.data.geo_lon
      });
      sendData.address = sendData.address.value;

      try {
        const res = await RequestManager.Place.updatePlace({ id: this.placeId, place: sendData });
        const promiseSend = [];
        let fix = Promise.resolve();
        promiseSend.push(fix);
        for (const photo of formData.courtmedia_set) {
          if (!photo.getFile().name) {
            continue;
          }
          let media = RequestManager.Place.addPlaceMedia({
            id: res.id,
            postData: { media: photo }
          });
          promiseSend.push(media);
        }
        Promise.all(promiseSend).then(() => {
          this.$toasts.push({
            message: 'Отдельная площадка успешно обновлена',
            duration: 4000,
          });
          this.$router.push({name: this.$routeName.PLACE_LIST });
        });
      } catch(e) {
        console.error(e);
      }
    },
  },
};
</script>
